import React from "react";
import styled from "styled-components";

export default (props) => {

  return (
    <Nav role="navigation" {...props} >
      <MenuWrapper>
        <StyledLink aria-current="page" itemprop="url" href="/" {...props} style={{ display: "flex" }}>
          <img height="40" width="120" src={`/${props.blog ? 'logo' : 'logo-white'}.svg`} alt="logo" style={{ margin: 0 }} />
        </StyledLink>
        <Menu>
          <MenuItem>
            <StyledLink itemprop="url" href="/about" {...props}>About</StyledLink>
          </MenuItem>
          <MenuItem>
            <StyledLink itemprop="url" href="/work-with-me" {...props}>Hire</StyledLink>
          </MenuItem>
          <MenuItem><StyledLink itemprop="url" href="/blog"{...props} >Blog</StyledLink></MenuItem>
        </Menu>
      </MenuWrapper>
    </Nav>
  );
}

const Nav = styled.nav`
  z-index: 1;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: ${props => props.blog ? '#fff' : 'transparent'};
  box-shadow: ${props => props.blog ? '0 5px 20px -10px #000' : 'none'};
`
const MenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
  padding: 10px 25px 10px 50px;
`
const Menu = styled.ul`
  display: flex;
  margin: 0;
`
const MenuItem = styled.li`
  margin: 0 10px;
  list-style: none;
`
const StyledLink = styled.a`
  color: ${props => props.blog ? '#000' : '#fff'};
  text-transform: uppercase;
  letter-spacing: 3.5px;
  font-weight: 200;
  text-decoration: none;
  font-family: sans-serif;
  border-bottom: 0px;
  box-shadow: 0 0;
  text-shadow: none;
  &:focus, &:hover, &:visited, &:link, &:active {
    text-decoration: none;
  }
`